// Generated by Framer (c75d380)

import { addFonts, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {E0OGMpVhC: {hover: true, pressed: true}};

const cycleOrder = ["E0OGMpVhC"];

const serializationHash = "framer-2tmsU"

const variantClassNames = {E0OGMpVhC: "framer-v-153f5dh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "E0OGMpVhC", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-153f5dh", className, classNames)} data-framer-name={"Default"} initial={variant} layoutDependency={layoutDependency} layoutId={"E0OGMpVhC"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, boxShadow: "0px 12px 24px 0px rgba(0, 0, 0, 0.2)", ...style}} {...addPropertyOverrides({"E0OGMpVhC-hover": {"data-framer-name": undefined}, "E0OGMpVhC-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Transition>
</LayoutGroup>)

});

const css = [".framer-2tmsU[data-border=\"true\"]::after, .framer-2tmsU [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2tmsU.framer-1xbdkcm, .framer-2tmsU .framer-1xbdkcm { display: block; }", ".framer-2tmsU.framer-153f5dh { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 24px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 24px; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-2tmsU.framer-153f5dh { gap: 0px; } .framer-2tmsU.framer-153f5dh > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-2tmsU.framer-153f5dh > :first-child { margin-left: 0px; } .framer-2tmsU.framer-153f5dh > :last-child { margin-right: 0px; } }", ".framer-2tmsU.framer-v-153f5dh.pressed.framer-153f5dh { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 20px); width: 20px; }", ".framer-2tmsU.framer-v-153f5dh.hover.framer-153f5dh { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 34px); width: 34px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"yiBQLj9LX":{"layout":["fixed","fixed"]},"RyA3cDjVi":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerNie4nCG0J: React.ComponentType<Props> = withCSS(Component, css, "framer-2tmsU") as typeof Component;
export default FramerNie4nCG0J;

FramerNie4nCG0J.displayName = "Label";

FramerNie4nCG0J.defaultProps = {height: 24, width: 24};

addFonts(FramerNie4nCG0J, [])